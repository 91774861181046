import { gql } from "@apollo/client";

export const GET_EXPERT = gql`
  query Expert {
    expert {
      expertUid
      status
      profiles {
        expertCode
        picture
        pictureUid
        pseudo
        skills
        quote
        vocalMessage
        spokenLanguages
        sponsorCode
        status
        parameters {
          phoneEnabled
          videoEnabled
          livechatEnabled
          writtenEnabled
          writtenLimit
          phoneUidForCalls
          phoneUidForAlerts
          autoPopQueue
          defaultPlanningChannels
          timezone
        }
        sponsor {
          expertUid
          pseudo
          picture
        }
        description
        writtenMessage
        profileLanguage
        validations {
          validationUid
          expertProfileUid
          validationType
          status
          valueBefore
          valueAfter
          refusalReason
          createdAt
          updatedAt
        }
        presenceStatus {
          phoneStatus
          videoStatus
          livechatStatus
          writtenStatus
          activityStatus
          lastActiveAt
        }
      }
      documents {
        docUid
        type
        links
        status
        refusalReason
      }
      pictures {
        pictureUid
        url
        createdAt
      }
      banking {
        beneficiary
        address
        zip
        city
        country
        portage
        bankCountry
        IBAN
        BIC
        SEPA
      }
      queue {
        queueUid
        items {
          queueItemUid
          queuerUid
          type
          position
          duration
          startedAt
          createdAt
          metadata {
            isBreak
            isEphemeral
            memberPseudo
            isManualPop
          }
        }
      }
      gender
      firstname
      lastname
      birthday
      email
      address
      complement
      zip
      city
      country
      isCompany
      companyName
      fiscalCompanyNumber
      mobilePhone
      language
      professionalCareer
      presenceHours {
        monday
        tuesday
        wednesday
        thursday
        friday
        saturday
        sunday
      }
    }
    expertPhones {
      phoneUid
      phoneNumber
      verified
      usages {
        calls
        fallbackCalls
        chatAlerts
      }
    }
  }
`;

export const GET_PENDING_ACTIONS = gql`
  query PendingActions($trial: Boolean) {
    pendingActions(trial: $trial) {
      privateMessagesNbr
      writtenNbr
      onGoingConsultation {
        channel
        channelRefUid
        consultationUid
        memberUid
        status
        createdAt
        startDate
        maxDuration
      }
      trialOffer {
        trialOfferUid
      }
      contractSignature {
        expertContractUid
        approbationDeadline
      }
    }
  }
`;

// TODO: remove refusalReason when trials using it are done
export const GET_RECRUITMENT = gql`
  query ExpertRecruitment {
    expertRecruitment {
      steps {
        admin {
          status
          refusalReason
          refusalReasons
        }
        profile {
          status
          refusalReason
          refusalReasons
        }
        trial {
          status
          refusalReason
          refusalReasons
        }
        contract {
          status
          refusalReason
          refusalReasons
        }
        global {
          status
          refusalReason
          refusalReasons
        }
      }
      status
    }
  }
`;

export const GET_EXPERT_CONTRACTS = gql`
  query Contracts($active: Boolean) {
    expert {
      expertUid
      contracts(active: $active) {
        expertContractUid
        pdfLink
        signed
        approbationDeadline
        validFrom
        validTo
        createdAt
        signedAt
        contractModel {
          context
          type
          language
          contractUid
        }
      }
    }
  }
`;

export const GET_PHONES = gql`
  query ExpertPhones {
    expertPhones {
      phoneUid
      phoneNumber
      country
      verified
      usages {
        calls
        fallbackCalls
        chatAlerts
      }
      servicePhones {
        calls
        chatAlerts
      }
    }
  }
`;

export const GET_CONSULTATIONS = gql`
  query Consultations($filters: ConsultationFilters) {
    consultations(filters: $filters) {
      totalRows
      data {
        consultationUid
        memberUid
        expertUid
        language
        duration
        maxDuration
        channel
        channelRefUid
        status
        failReason
        rating {
          ratingUid
          rate
          memberPseudo
          comment
          status
          ratingAnswer {
            ratingAnswerUid
            ratingUid
            expertUid
            comment
            validationComment
            language
            status
            validationRules
            context
            createdAt
            updatedAt
            publishAt
          }
          createdAt
        }
        startDate
        endDate
        createdAt
        purgeDate
      }
    }
  }
`;

export const GET_EXPERT_RELATIONS = gql`
  query ExpertRelations($memberUids: [String], $memberPseudo: String, $limit: Int, $offset: Int) {
    expertRelations(memberUids: $memberUids, memberPseudo: $memberPseudo, limit: $limit, offset: $offset) {
      totalRows
      data {
        relationUid
        memberUid
        memberPseudo
        memberBirthday
        phoneNbr
        videoNbr
        livechatNbr
        writtenNbr
        ratingNbr
        messageNbr
        expertNotes
        customData {
          name
        }
      }
    }
  }
`;

export const GET_EXPERT_RELATION = gql`
  query ExpertRelations($searchUid: ID!, $searchType: SearchType) {
    expertRelation(searchUid: $searchUid, searchType: $searchType) {
      relationUid
      memberUid
      memberPseudo
      memberBirthday
      phoneNbr
      videoNbr
      livechatNbr
      writtenNbr
      ratingNbr
      messageNbr
      expertNotes
      customData {
        name
      }
    }
  }
`;

export const GET_CONTENT = gql`
  query Content($locale: String!, $namespace: String!) {
    content(locale: $locale, namespace: $namespace) {
      content
      key
      namespace
    }
  }
`;

export const GET_MESSAGES_SUMMARY = gql`
  query MessagesSummary($correspondent: String) {
    messagesSummary(correspondent: $correspondent) {
      recipient
      unreadMsg
      blocked
      blockedBy
      lastMsgDate
    }
  }
`;

export const GET_MESSAGES_LIST = gql`
  query MessagesList($correspondent: String!, $limit: Int!, $offset: Int!) {
    messagesList(correspondent: $correspondent, limit: $limit, offset: $offset) {
      totalRows
      data {
        attachments
        content
        messageUid
        sentAt
        recipient
        sender
        refusalReason
        viewedAt
        deletedAt
      }
    }
  }
`;

export const GET_WRITTEN = gql`
  query Written($writtenUid: ID!) {
    written(writtenUid: $writtenUid) {
      writtenUid
      memberUid
      expertUid
      subject
      message
      attachments
      answer
      status
      refusalReason
      refusalComment
      createdAt
      answeredAt
      expireAt
    }
  }
`;

export const GET_LIVECHAT_ROOM = gql`
  query LivechatRoom($livechatRoomUid: ID!) {
    livechatRoom(livechatRoomUid: $livechatRoomUid) {
      livechatRoomUid
      participants {
        name
        userUid
      }
      messages {
        origin
        ts
        payload
      }
      maxTime
      context
      createdAt
      startDate
      endDate
      status
    }
  }
`;

export const GET_VIDEO_ROOM = gql`
  query VideoRoom($videoRoomUid: ID!) {
    videoRoom(videoRoomUid: $videoRoomUid) {
      videoRoomUid
      maxTime
      context
      createdAt
      startDate
      endDate
      participants {
        name
        userUid
        online
        isMe
        connectionIds
      }
      sessionId
      token
      status
    }
  }
`;

export const GET_RTC_TOKEN = gql`
  query RTC {
    rtcToken
  }
`;

export const GET_PLANNING_EFFECTIVE = gql`
  query PlanningEffective($from: String!, $to: String!, $timezone: String) {
    planningEffective(from: $from, to: $to, timezone: $timezone) {
      slots {
        start
        end
        channels
      }
      queueSlots {
        start
        end
        channels
      }
    }
  }
`;

export const GET_PLANNING_VACATIONS = gql`
  query PlanningVacations($from: String!, $to: String!) {
    planningVacations(from: $from, to: $to) {
      from
      to
    }
  }
`;

export const GET_TRIALS = gql`
  query ExpertTrials {
    expertTrials {
      trialUid
      expertUid
      channel
      consultationLimit
      consultationProgress
      status
      createdAt
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  query Notifications($offset: Int!, $limit: Int!) {
    notification {
      notifications(offset: $offset, limit: $limit) {
        notificationUid
        ack
        notificationType
        metadata
        priority
        createdAt
      }
      unreadNotificationsCount
    }
  }
`;

export const GET_IVR_CALL = gql`
  query IvrCalls($ivrCallUid: String) {
    ivrCalls(ivrCallUid: $ivrCallUid) {
      ivrCallUid
      expertCallUid
      expertUid
    }
  }
`;

export const GET_EXPERT_SPONSOR_CODE_VALIDATION = gql`
  query ExpertSponsorCodeValidation($sponsorCode: String!) {
    expertSponsorCodeValidation(sponsorCode: $sponsorCode)
  }
`;

export const GET_SPONSORED_EXPERT_PROFILES = gql`
  query SponsoredExpertProfiles {
    sponsoredExpertProfiles {
      currentGainRetribution
      estimatedGainRetribution
      expertUid
      picture
      pseudo
    }
  }
`;

export const GET_GLOBAL = gql`
  query Global {
    global {
      ipCountry
    }
  }
`;

export const GET_ANNOUNCEMENTS = gql`
  query Announcements {
    announcements {
      announcementUid
      type
      typeData
      content
      notify
      startDate
      endDate
    }
  }
`;

export const GET_EXPERT_RETRIBUTION = gql`
  query ExpertRetribution {
    expertRetribution {
      phoneCount
      phoneMissed
      phonePenalized
      phonePenalty
      phonePenaltyAmount
      phoneDuration
      phoneAmount
      videoCount
      videoMissed
      videoPenalized
      videoPenalty
      videoPenaltyAmount
      videoDuration
      videoAmount
      livechatCount
      livechatMissed
      livechatPenalized
      livechatPenalty
      livechatPenaltyAmount
      livechatDuration
      livechatAmount
      writtenCount
      writtenMissed
      writtenAmount
      writtenPenaltyAmount
      sponsorAmount
      presence
      month
      year
      baseRetribution
      retribution
      totalAmount
      previousMonthRetribution {
        totalAmount
        livechatDuration
        phoneDuration
        presence
      }
      monthlyRetribParameters {
        retributionBaseGroup
        retribMonthlyOtherAmount
        retribMonthlyRecurringAmount
        writtenRetribution
        seniorityDate
        presenceBonuses {
          threshold
          rewards {
            presenceBonus
          }
          enabled
        }
      }
    }
  }
`;

export const GET_EXPERT_RETRIB_STATS = gql`
  query ExpertRetributionStats($year: Int!, $month: Int) {
    expertRetributionStats(year: $year, month: $month) {
      retributions {
        phoneCount
        phoneMissed
        phonePenalized
        phonePenalty
        phonePenaltyAmount
        phoneDuration
        phoneAmount
        videoCount
        videoMissed
        videoPenalized
        videoPenalty
        videoPenaltyAmount
        videoDuration
        videoAmount
        livechatCount
        livechatMissed
        livechatPenalized
        livechatPenalty
        livechatPenaltyAmount
        livechatDuration
        livechatAmount
        writtenCount
        writtenMissed
        writtenAmount
        writtenPenaltyAmount
        sponsorAmount
        presence
        month
        year
        retribution
        totalAmount
        previousMonthRetribution {
          totalAmount
          livechatDuration
          phoneDuration
          videoDuration
          presence
        }
        monthlyRetribParameters {
          writtenRetribution
          writtenMonthlyVariableNbr
          retribMonthlyOtherAmount
          retribMonthlyRecurringAmount
          phoneMonthlyVariableMinutes
          phoneMonthlyVariableNbr
          videoMonthlyVariableMinutes
          videoMonthlyVariableNbr
          livechatMonthlyVariableMinutes
          livechatMonthlyVariableNbr
          presenceBonuses {
            threshold
            rewards {
              presenceBonus
            }
            enabled
          }
        }
      }
      stats {
        day
        phoneCount
        phoneMissed
        phonePenalized
        phoneDuration
        videoCount
        videoMissed
        videoPenalized
        videoDuration
        livechatCount
        livechatMissed
        livechatPenalized
        livechatDuration
        writtenCount
        writtenMissed
        presence
      }
    }
  }
`;

export const GET_EXPERT_RETRIBUTION_MONTH = gql`
  query ExpertRetributionStats($year: Int!, $month: Int) {
    expertRetributionStats(year: $year, month: $month) {
      retributions {
        phonePenalty
        phonePenaltyAmount
        phoneDuration
        phoneAmount
        videoPenalty
        videoPenaltyAmount
        videoDuration
        videoAmount
        livechatPenalty
        livechatPenaltyAmount
        livechatDuration
        livechatAmount
        writtenCount
        writtenMissed
        writtenAmount
        writtenPenaltyAmount
        sponsorAmount
        month
        year
        retribution
        totalAmount
        monthlyRetribParameters {
          writtenRetribution
          baseRetribution
          presenceBonus
          seniorityBonus
          retribMonthlyOtherAmount
        }
      }
    }
  }
`;

export const GET_INVOICES = gql`
  query Invoices($year: Int!) {
    invoices(year: $year) {
      invoiceUid
      month
      year
      amount
      file
      status
      refusalReason
      createdAt
      exportedAt
    }
  }
`;

export const GET_INVOICE = gql`
  query Invoice($invoiceUid: ID!) {
    invoice(invoiceUid: $invoiceUid) {
      invoiceUid
      month
      year
      amount
      file
      status
      refusalReason
      createdAt
      exportedAt
    }
  }
`;

export const GET_MONTHLY_CONSULTATIONS = gql`
  query Consultations($filters: ConsultationFilters) {
    consultations(filters: $filters) {
      data {
        consultationUid
        channel
        duration
        memberUid
        status
        failReason
        startDate
        endDate
        createdAt
      }
      totalRows
    }
  }
`;

export const GET_SUPPORT_TICKETS = gql`
query Tickets($filters: TicketFilters) {
  tickets(filters: $filters) {
    data {
      userEmail
      ticketUid
      subject
      status
      userFirstname
      userLastname
      userPseudo
      createdAt
    }
    totalRows
  }
}
`;

export const GET_SUPPORT_TICKET = gql`
query Ticket($ticketUid: String!) {
  ticket(ticketUid: $ticketUid) {
    createdAt
    status
    subject
    userFirstname
    userLastname
    userEmail
    userPseudo
    userUid
    userType
    exchanges {
      ticketExchangeUid
      content
      origin
      createdAt
      attachments
      userPseudo
    }
  }
}
`;

export const GET_ADVISORIES = gql`
query Advisories($type: String!, $targetUid: String) {
  advisories(type: $type, targetUid: $targetUid) {
    data {
      advisoryUid
      type
      targetType
      targetUid
      expiresAt
      createdAt
    }
    totalRows
  }
}`;

// #region Survey
export const GET_ACTIVE_SURVEYS = gql`
  query GetActiveSurveys {
    activeSurveys {
      surveyUid
      title
      description
      language
      createdAt
      startDate
      endDate
      targetQuery
      singleResponse
      country
      status
      context
      userType
      questions {
        questionUid
        title
        content
        required
        showQuestion
        showDescription
        type
        settings {
          allowOther
          options {
            key
            label
            action {
              type
              questionUid
            }
          }
          min
          max
          step
          minLabel
          midLabel
          maxLabel
          collectEmail
          button {
            text
            link
          }
        }
        order
      }
    }
  }
`;
// #endregion
